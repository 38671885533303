import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';

import { Colors } from '../theme/colors';

interface AppCardProps {
  icon?: ReactNode;
  title: string;
  value?: string | number;
  selected?: boolean;
  onSelect?: () => void;
  loading?: boolean;
  refreshing?: boolean;
  sx?: SxProps<Theme>;
  valueStyle?: SxProps<Theme>;
  dataTestId?: string;
}

const AppCard = ({
  icon,
  title,
  value,
  selected = false,
  dataTestId,
  onSelect,
  loading = false,
  refreshing = false,
  sx = {},
  valueStyle = {},
}: AppCardProps) => {
  const selectedBackgroundColor = selected
    ? Colors.primaryLight
    : Colors.backgroundPrimary;
  const selectedTextColor = selected ? Colors.primary : Colors.fontColorPrimary;
  const selectedBorderColor = selected
    ? Colors.primary
    : Colors.greyBorderLogin;

  const cardStyles = {
    display: 'flex',
    flexGrow: 1,
    flexBasis: 0,
    alignItems: 'center',
    padding: 2,
    backgroundColor: loading
      ? Colors.backgroundPrimary
      : selectedBackgroundColor,
    borderColor: loading ? Colors.greyBorderLogin : selectedBorderColor,
    borderWidth: 2,
    boxShadow: 'none',
    borderStyle: 'solid',
    cursor: loading ? 'default' : 'pointer',
    height: '85px',
    ...sx,
  };

  const iconStyles = {
    mr: 2,
    my: 0.5,
    color: loading ? Colors.fontColorPrimary : selectedTextColor,
    ...sx,
  };

  const titleStyles = {
    mt: value ? 1 : 0,
    color: loading ? Colors.fontColorPrimary : selectedTextColor,
    fontWeight: 500,
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
  };

  const valueStyles = {
    color: loading ? Colors.fontColorPrimary : selectedTextColor,
    fontWeight: 700,
    fontSize: '24px',
    letterSpacing: '-1.2px',
    ...valueStyle,
  };

  const loadingPlaceholderStyles = {
    backgroundColor:
      refreshing && selected ? Colors.primary : Colors.chipDefaultBackground,
    height: '10px',
    width: '100%',
    borderRadius: '5px',
    mt: 1.4,
    mb: 1.9,
  };

  return (
    <Card
      sx={cardStyles}
      onClick={!loading && onSelect ? onSelect : undefined}
      data-testid={dataTestId}>
      {icon && <Box sx={iconStyles}>{icon}</Box>}
      <Box>
        <Typography sx={titleStyles}>{title}</Typography>
        {loading || refreshing ? (
          <Box sx={loadingPlaceholderStyles} />
        ) : (
          value !== null && <Typography sx={valueStyles}>{value}</Typography>
        )}
      </Box>
    </Card>
  );
};

export default AppCard;
