import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import { useMemo } from 'react';

import AppDateTimePicker from '../../../components/AppDateTimeRangePicker';
import AppExportButton from '../../../components/AppExportButton';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { setReportDates } from '../../../redux/AppSlice';
import { selectApp } from '../../../redux/selectors/appSelectors';
import { selectOrderCountSummary } from '../../../redux/selectors/orderCountSummarySelectors';
import { Colors } from '../../../theme/colors';
import { PaginatedResponseDTO } from '../../../types/api.types';
import { fetchOrderSummaryExport } from '../redux/orderCountSummarySlice';
import {
  IOrderCountSummaryTable,
  OrderCountSummaryResponseDTO,
} from '../types/reports.types';
import { getFormattedOrderAnalytics } from '../utils/formatting.utils';

function OrderSummaryHeader() {
  const dispatch = useAppDispatch();

  const { columns, refreshing } = useAppSelector(selectOrderCountSummary);

  const { reportStartDate, reportEndDate } = useAppSelector(selectApp);

  const onCalendarSave = (start: string | null, end: string | null) => {
    dispatch(
      setReportDates({
        reportStartDate: start ?? undefined,
        reportEndDate: end ?? undefined,
      }),
    );
  };

  const debouncedFetchExportData = useMemo(
    () =>
      debounce(
        async () => {
          const response = await dispatch(
            fetchOrderSummaryExport({
              date_from: reportStartDate ?? undefined,
              date_to: reportEndDate,
            }),
          );

          let results: OrderCountSummaryResponseDTO[] = [];

          if (response.meta.requestStatus === 'fulfilled' && response.payload) {
            const payload = response.payload as PaginatedResponseDTO<
              OrderCountSummaryResponseDTO,
              never
            >;

            results = payload.results;
          }

          return results;
        },
        500,
        { leading: true, trailing: false },
      ),
    [dispatch, reportEndDate, reportStartDate],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        mb: 2,
        border: `1px solid ${Colors.backgroundShade}`,
        backgroundColor: Colors.backgroundSecondary,
      }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', p: 1.2 }}>
        <AppDateTimePicker
          defaultValues={[dayjs.tz(reportStartDate), dayjs.tz(reportEndDate)]}
          disabled={refreshing}
          onSave={onCalendarSave}
        />
        <Box sx={{ ml: 1.2 }} />
        <AppExportButton<OrderCountSummaryResponseDTO, IOrderCountSummaryTable>
          fileName={`orders`}
          columns={columns}
          dataFetcher={debouncedFetchExportData}
          formatter={getFormattedOrderAnalytics}
          hideColumnSelector={true}
          buttonSx={{ height: 30, fontSize: '12px' }}
          iconSx={{ width: 14 }}
        />
      </Box>
    </Box>
  );
}

export default OrderSummaryHeader;
