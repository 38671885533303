import Box from '@mui/material/Box';
import { useCallback, useEffect, useMemo } from 'react';

import AppTable from '../../../components/AppTable';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { updatePaymentsReportLastUpdated } from '../../../redux/AppSlice';
import { selectApp } from '../../../redux/selectors/appSelectors';
import { selectAuth } from '../../../redux/selectors/authSelectors';
import { selectOrderCountSummary } from '../../../redux/selectors/orderCountSummarySelectors';
import ReportsEmptyTable from '../components/ReportsEmptyTable';
import { fetchOrderSummary } from '../redux/orderCountSummarySlice';
import { getFormattedOrderAnalytics } from '../utils/formatting.utils';

const OrderSummary = () => {
  const dispatch = useAppDispatch();

  const { columns, refreshing, orderSummaryData, lastUpdated } = useAppSelector(
    selectOrderCountSummary,
  );

  const { reportStartDate, reportEndDate } = useAppSelector(selectApp);

  const { restaurant } = useAppSelector(selectAuth);

  const getData = useCallback(async () => {
    await dispatch(
      fetchOrderSummary({
        date_from: reportStartDate,
        date_to: reportEndDate,
        offset: restaurant?.timeZoneValue,
      }),
    );
  }, [dispatch, reportStartDate, reportEndDate, restaurant?.timeZoneValue]);

  useEffect(() => {
    getData();
  }, [dispatch, getData]);

  useEffect(() => {
    dispatch(updatePaymentsReportLastUpdated(lastUpdated));
  }, [dispatch, lastUpdated]);

  const rowData = useMemo(() => {
    return orderSummaryData.map(getFormattedOrderAnalytics);
  }, [orderSummaryData]);

  return (
    <Box
      sx={{
        width: '100%',
        mb: 5,
        mt: 2,
      }}
      data-testid="Order Analytics Summary">
      <AppTable
        columns={columns}
        rows={rowData}
        refreshing={refreshing}
        emptyComponent={<ReportsEmptyTable title={'No Orders found'} />}
        enablePagination={false}
      />
    </Box>
  );
};

export default OrderSummary;
