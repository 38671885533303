import ReportsLayout from '../../../hocs/ReportsLayout';
import OrderSummary from '../../reports/views/OrderSummary';
import PaymentsReportLastUpdateAtFooter from '../components/PaymentsReportLastUpdateAtFooter';
import OrderSummaryFilters from '../views/OrderSummaryFilter';
import OrderSummaryHeader from '../views/OrderSummaryHeader';

function OrderSummaryPage() {
  return (
    <ReportsLayout
      title="Order Summary"
      ContentComponent={OrderSummary}
      FilterComponent={OrderSummaryFilters}
      HeaderComponent={OrderSummaryHeader}
      FooterComponent={PaymentsReportLastUpdateAtFooter}
    />
  );
}

export default OrderSummaryPage;
