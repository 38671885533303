import Box from '@mui/material/Box';

import AppCard from '../../../components/AppCard';
import { useAppSelector } from '../../../hooks';
import { selectOrderCountSummary } from '../../../redux/selectors/orderCountSummarySelectors';

function OrderSummaryFilter() {
  const { orderCounts } = useAppSelector(selectOrderCountSummary);

  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      <AppCard title="DINE-IN" value={orderCounts.dineInCounts} />
      <AppCard title="TAKEAWAY" value={orderCounts.pickUpCounts} />
      <AppCard title="DELIVERY" value={orderCounts.deliveryCounts} />
      <AppCard title="OTHER PAYMENTS" value={orderCounts.nonEatMeCounts} />
      <AppCard title="TOTAL" value={orderCounts.totalCounts} />
    </Box>
  );
}

export default OrderSummaryFilter;
